import * as React from 'react'
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import { ButtonGroup } from 'react-bootstrap'
import Button from '@mui/material/Button'
import Aws from '../../CloudPlatform_Images/aws.png'
import Cmp from '../../CloudPlatform_Images/cmp.png'
import Gcp from '../../CloudPlatform_Images/gcp.png'
import Azure from '../../CloudPlatform_Images/azure.png'
import TableHealth from '../HealthCard/HealthCard'
import './navbar.css'
import '../../LoaderSpinner/loaderspinner.css'
import { MenuItem, Select } from '@mui/material'
import TextField from '@mui/material/TextField'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { useSelector } from 'react-redux'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Tooltip from '@mui/material/Tooltip'
import Fade from '@mui/material/Fade'
import AWSHealthDashboard from '../AWShealthDashboard/awsHealthDashboard'
import { api } from '../../axiosWrapper'
import { useCallback } from 'react'
import CustomTabs from './CustomTabs'
import { StyledPopper } from '../SubscriptionModal/utils/CustomAutocomplete/customAutocomplete'
function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const TabWithCount = ({ count, image, width, height }) => {
    return (
        <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: '3px' }}>
            <img
                src={image}
                width={width}
                height={height}
                alt="CloudPlatformLogo"
            />
            <p className="navbarLabels">{count}</p>
        </Box>
    )
}

export default function NavBar() {
    const regions = [
        { region: 'Americas' },
        { region: 'Europe' },
        { region: 'Asia Pacific' },
    ]

    const awsRegions = [
        { region: 'global', label: 'Global' },
        { region: 'north_america', label: 'North America' },
        { region: 'south_america', label: 'South America' },
        { region: 'europe', label: 'Europe' },
        { region: 'africa', label: 'Africa' },
        { region: 'asia_pacific', label: 'Asia Pacific' },
        { region: 'middle_east', label: 'Middle East' },
    ]

    const gcpRegions = [
        { region: 'global', label: 'Global' },
        { region: 'americas', label: 'Americas' },
        { region: 'europe', label: 'Europe' },
        { region: 'africa', label: 'Africa' },
        { region: 'asia_pacific', label: 'Asia Pacific' },
        { region: 'middle_east', label: 'Middle East' },
    ]

    const azureRegions = [
        { region: 'americas', label: 'Americas' },
        { region: 'europe', label: 'Europe' },
        { region: 'asia_pacific', label: 'Asia Pacific' },
        { region: 'africa', label: 'Africa' },
        { region: 'middle_east', label: 'Middle East' },
    ]
    const regionsMapper = {
        0: 'Americas',
        1: 'Europe',
        2: 'Asia Pacific',
    }

    const azureRegionMapper = {
        0: 'americas',
        1: 'europe',
        2: 'asia_pacific',
        3: 'africa',
        4: 'middle_east',
    }

    const categoryMapper = {
        0: 'CMP',
        1: 'CloudNetwork',
        2: 'Azure',
        3: 'AWS',
        4: 'GCP',
    }
    const deviceTypeMapper = [
        { label: 'Palo Alto', value: 'palo-alto' },
        { label: 'F5', value: 'F5' },
    ]

    // const [value, setValue] = React.useState(3);
    // Use Above one when Implementing User Preferences!!!
    const [value, setValue] = React.useState(0)
    const [apiData, setApiData] = useState({ data: [], empty: 'false' })
    const [active2, setActive] = useState(0)
    const [loading, setLoading] = useState(false)
    const [searchString, setSearchString] = useState('')
    const [csp, setCSP] = useState('Azure')
    const [cachedApiData, setCachedApiData] = useState([])
    const [searchLabelText, setSearchLabelText] = useState('')
    const [categorySelector, setCategorySelector] = useState(
        deviceTypeMapper[0].value
    )
    const [awsSelectedRegion, setAwsSelectedRegion] = useState('global')
    const [gcpSelectedRegion, setGcpSelectedRegion] = useState('global')
    const [selectedOption, setSelectedOption] = useState(null)

    // const userPreferences = useSelector(
    //   (state) => state?.userPreferences?.preferences?.ux_preferences
    // );
    const mapCategoryToIndex = {
        CMP: 0,
        CloudNetwork: 1,
        Azure: 2,
        AWS: 3,
        GCP: 4,
    }
    const awsRegionsMapper = {
        0: 'global',
        1: 'north_america',
        2: 'south_america',
        3: 'europe',
        4: 'africa',
        5: 'asia_pacific',
        6: 'middle_east',
    }

    const gcpRegionsMapper = {
        0: 'global',
        1: 'americas',
        2: 'europe',
        3: 'africa',
        4: 'asia_pacific',
        5: 'middle_east',
    }

    const mapRegiontoIndex = {
        Americas: 0,
        Europe: 1,
        'Asia Pacific': 2,
    }

    const deviceTypePreferenceMapper = {
        paloAlto: 'palo-alto',
        F5: 'F5',
    }

    // const filterOptions = createFilterOptions({
    //   matchFrom: "any",
    //   stringify: (option) => [option?._id, option?.data?.ip],
    // });

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        stringify: (option) => {
            const idValue = option?._id || option.serviceName || ''
            const ipValues = option?.data
                ?.map((item) => item?.ip || '')
                ?.filter(Boolean)
            return `${idValue} ${ipValues?.join(' ')}`
        },
    })

    // const returnCategoryIndex = async (userPreferences) => {

    // };

    //styled component

    useEffect(() => {
        switch (value) {
            case 0:
                setSearchLabelText('Search CMP Products')
                break
            case 1:
                setSearchLabelText(
                    `Search ${
                        categorySelector === 'F5' ? 'F5' : 'Palo Alto'
                    } Devices by IP or Name`
                )
                break
            case 2:
                setSearchLabelText('Search Azure Services')
                break
            case 3:
                setSearchLabelText('Search AWS Services')
                break
            case 4:
                setSearchLabelText('Search GCP Services')
                break
            default:
                setSearchLabelText('Search Services')
        }
    }, [value, categorySelector])

    // const syncAllUserPreferences = async () => {
    //   const result = userPreferences ? userPreferences?.split("-") : null;
    //   console.log(result);
    //   setValue(mapCategoryToIndex[result[1]]);
    //   setActive(mapRegiontoIndex[result[2]]);
    //   setCategorySelector(deviceTypePreferenceMapper[result[3]]);
    //   setCSP(result[4]);
    // };

    const serverUrl = process.env.REACT_APP_BACKEND_URL
    const fetchData = async () => {
        setApiData([])
        setLoading(true)

        try {
            let endpoint = ''

            switch (value) {
                case 0:
                    endpoint = `/api/${categoryMapper[value]}`
                    break
                case 1:
                    endpoint = `/api/cloud/${categorySelector}/${regionsMapper[active2]}?csp=${csp}`
                    break
                case 2:
                    endpoint = `/api/azure/${azureRegionMapper[active2]}`
                    break
                case 3:
                    endpoint = `/api/aws/${awsSelectedRegion}`
                    break
                case 4:
                    endpoint = `/api/gcp/${gcpSelectedRegion}`
                    break
                default:
                    endpoint = `/api/others/${categoryMapper[value]}`
            }

            const res = await api.get(`${serverUrl}${endpoint}`)

            if (res.data && res.data.length > 0) {
                if (value === 0) {
                    const result = res.data.sort((a, b) =>
                        a._id.localeCompare(b._id)
                    )
                    setApiData({ data: result, empty: 'false' })

                    setCachedApiData({ data: result, empty: 'false' })
                } else {
                    setApiData({ data: res.data, empty: 'false' })
                    setCachedApiData({ data: res.data, empty: 'false' })
                }
            } else {
                setApiData({ data: [], empty: 'true' })
            }
        } catch (error) {
            // Handle error
        } finally {
            setLoading(false)
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const setActiveButton = (index) => {
        setActive(index)
    }

    const cspOptions = [
        { label: 'Azure', value: 'Azure' },
        { label: 'AWS', value: 'AWS' },
        { label: 'GCP', value: 'GCP' },
    ]

    const handleSearchChange = (e, v) => {
        // console.log(v?._id);
        setSearchString(v?._id || v?.serviceName || '')
    }

    useEffect(() => {
        if (searchString === '' || !searchString) {
            setApiData(cachedApiData)
        } else {
            const result = cachedApiData.data.filter((item) => {
                return (
                    item?._id?.toLowerCase() || item.serviceName.toLowerCase()
                ).includes(searchString.toLowerCase())
            })
            setApiData({ data: result, empty: 'false' })
        }
    }, [searchString])

    useEffect(() => {
        setGcpSelectedRegion('global')
        setAwsSelectedRegion('global')
        setSearchString('')
        if (active2 === 0) {
            fetchData()
        } else {
            setActive(0)
        }
    }, [value])

    useEffect(() => {
        setSearchString('')
        setSelectedOption(null)
    }, [
        value,
        active2,
        awsSelectedRegion,
        gcpSelectedRegion,
        categorySelector,
        csp,
    ])

    useEffect(() => {
        const interval = setInterval(() => {
            fetchData()
        }, 90000)

        return () => clearInterval(interval)
    }, [value, active2])

    useEffect(() => {
        fetchData()
    }, [categorySelector, csp, awsSelectedRegion, gcpSelectedRegion, active2])

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <>
            <Box sx={{ width: '100%' }} className="service-insight-label">
                <Box>
                    <Box
                        component="span"
                        m={1}
                        pt={4}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Tabs
                            TabIndicatorProps={{
                                style: { background: '#00A3E0', height: '4px' },
                            }}
                            sx={{ paddingLeft: '5px' }}
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                        >
                            <Tab
                                label={
                                    <TabWithCount
                                        count="CMP"
                                        image={Cmp}
                                        width="54px"
                                        height="40px"
                                    ></TabWithCount>
                                }
                                {...a11yProps(0)}
                            />
                            <Tab
                                label={
                                    <TabWithCount
                                        count="Cloud Network"
                                        image={Cmp}
                                        width="54px"
                                        height="40px"
                                    ></TabWithCount>
                                }
                                {...a11yProps(0)}
                            />
                            <Tab
                                label={
                                    <TabWithCount
                                        count="Azure"
                                        image={Azure}
                                        width="48px"
                                        height="30px"
                                    >
                                        Azure
                                    </TabWithCount>
                                }
                                {...a11yProps(0)}
                            />
                            <Tab
                                label={
                                    <TabWithCount
                                        count="AWS"
                                        image={Aws}
                                        width="44px"
                                        height="30px"
                                    >
                                        AWS
                                    </TabWithCount>
                                }
                                {...a11yProps(0)}
                            />
                            <Tab
                                label={
                                    <TabWithCount
                                        count="GCP"
                                        image={Gcp}
                                        width="34px"
                                        height="30px"
                                    ></TabWithCount>
                                }
                                {...a11yProps(0)}
                            />
                        </Tabs>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <Autocomplete
                                disablePortal
                                PopperComponent={StyledPopper} // Use the styled Popper component
                                key={value}
                                className="search_bar"
                                filterOptions={filterOptions}
                                id="combo-box-demo"
                                getOptionLabel={(healthData) =>
                                    healthData._id ||
                                    healthData.serviceName ||
                                    ''
                                }
                                sx={{
                                    '.MuiOutlinedInput-root': {
                                        borderRadius: '10px',
                                        padding: '0px',
                                        border: '1px solid var(--search_bar_outline)',
                                    },
                                    width: 300,
                                    color: 'var(--hour_color)',
                                    '.MuiAutocomplete-inputRoot': {
                                        display: 'flex',
                                        alignItems: 'center',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        paddingRight: '30px !important', // Adjust padding to create space for the "x" mark
                                    },
                                }}
                                onChange={(e, v) => {
                                    setSelectedOption(v) // Update the selected option state
                                    handleSearchChange(e, v) // Call your existing change handler
                                }}
                                value={selectedOption}
                                options={apiData.data || []}
                                renderInput={(params) => (
                                    <TextField
                                        className="autocomplete-search-text-field"
                                        {...params}
                                        label={searchLabelText}
                                        sx={{
                                            color: 'var(--hour_color)',
                                            '& .MuiInputLabel-root': {
                                                top: '-7px',
                                                color: 'var(--search_service_label_color)',
                                            },
                                            '& .MuiSvgIcon-root': {
                                                color: 'var(--search_service_label_color)',
                                            },
                                            '.MuiInputBase-input': {
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                paddingRight: '30px !important', // Ensure this matches the padding added to the input root
                                            },
                                        }}
                                    />
                                )}
                            />

                            <Tooltip
                                arrow
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                fontSize="medium"
                                placement="top"
                                title="This is an AutoComplete Searchbox. Start typing to get suggestions"
                            >
                                <span>
                                    <InfoOutlinedIcon className="info-icon-alerts-modal" />
                                </span>
                            </Tooltip>
                        </div>
                    </Box>

                    <>
                        {[2].includes(value) ? (
                            <CustomTabs
                                regions={azureRegions}
                                active={active2}
                                onClick={(index) => {
                                    setAwsSelectedRegion(
                                        azureRegionMapper[index]
                                    )
                                    setActive(index)
                                }}
                            />
                        ) : [3].includes(value) ? (
                            <CustomTabs
                                regions={awsRegions}
                                active={active2}
                                onClick={(index) => {
                                    setAwsSelectedRegion(
                                        awsRegionsMapper[index]
                                    )
                                    setActive(index)
                                }}
                            />
                        ) : [4].includes(value) ? (
                            <CustomTabs
                                regions={gcpRegions}
                                active={active2}
                                onClick={(index) => {
                                    setGcpSelectedRegion(
                                        gcpRegionsMapper[index]
                                    )
                                    setActive(index)
                                }}
                            />
                        ) : (
                            <div className="region-dropdown-flex">
                                <div style={{ display: 'flex' }}>
                                    {regions.map(
                                        (region, index) =>
                                            [1, 2].includes(value) && (
                                                <div
                                                    key={index}
                                                    className="aws-btn-containers"
                                                >
                                                    <button
                                                        className={
                                                            active2 === index
                                                                ? 'btn-aws btn-aws-active'
                                                                : 'btn-aws btn-aws-inactive'
                                                        }
                                                        onClick={() => {
                                                            setActiveButton(
                                                                index
                                                            )
                                                        }}
                                                    >
                                                        {region.region}
                                                    </button>
                                                </div>
                                            )
                                    )}
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'baseline',
                                        marginTop: ' -18px',
                                    }}
                                >
                                    {value === 1 && (
                                        <>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    marginRight: '10px',
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        fontWeight: 500,
                                                        fontSize: '12px',
                                                        marginBottom: '0px',
                                                    }}
                                                >
                                                    Select Device &nbsp;&nbsp;
                                                </p>
                                                <Select
                                                    className="device-selection"
                                                    MenuProps={{
                                                        MenuListProps: {
                                                            style: {
                                                                backgroundColor:
                                                                    'var(--search_bar_dark_bg)',
                                                                color: 'var(--body_color)',
                                                            },
                                                        },
                                                    }}
                                                    onChange={(e) => {
                                                        setCategorySelector(
                                                            e.target.value
                                                        )
                                                    }}
                                                    value={categorySelector}
                                                >
                                                    {deviceTypeMapper.map(
                                                        (e, i) => (
                                                            <MenuItem
                                                                key={i}
                                                                value={e.value}
                                                            >
                                                                {e.label}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </div>

                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        fontWeight: 500,
                                                        fontSize: '12px',
                                                        marginBottom: '0px',
                                                    }}
                                                >
                                                    CSP &nbsp;&nbsp;
                                                </p>
                                                <Select
                                                    className="device-selection"
                                                    MenuProps={{
                                                        MenuListProps: {
                                                            style: {
                                                                backgroundColor:
                                                                    'var(--search_bar_dark_bg)',
                                                                color: 'var(--body_color)',
                                                            },
                                                        },
                                                    }}
                                                    onChange={(e) => {
                                                        setCSP(e.target.value)
                                                    }}
                                                    value={csp}
                                                >
                                                    {cspOptions.map((e, i) => (
                                                        <MenuItem
                                                            key={i}
                                                            value={e.value}
                                                        >
                                                            {e.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                    </>
                </Box>
                {loading ? (
                    <div className="table-loader-container">
                        <div className="loader" data-testid="navbar-loader" />
                    </div>
                ) : [2].includes(value) ||
                  [3].includes(value) ||
                  [4].includes(value) ? (
                    <AWSHealthDashboard apiData={apiData} />
                ) : (
                    <TableHealth apiData={apiData} category={value} />
                )}
            </Box>
        </>
    )
}
