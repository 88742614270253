import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import React, { useCallback, useEffect, useState } from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import './subscriptionModal.css'
import WelcomeContainer from './WelcomeContainer.js'
import CurrentSubscriptions from '../CurrentSubscriptions/CurrentSubscriptions'
import Fade from '@mui/material/Fade'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { useDispatch, useSelector } from 'react-redux'
import Tooltip from '@mui/material/Tooltip'
import ReusableDropdown from '../ReusableDropdown/ReusableDropdown'
import NewSubscriptionsList from '../New Subscriptions/NewSubscriptionsList'
import { Button, CircularProgress } from '@mui/material'
import { updatePreferences } from './updatePreferences'
import { url } from '../../serverUrl'
import {
    setRawPreferences,
    setUserInfo,
} from '../../redux/userPreferencesSlice'
import toast, { Toaster } from 'react-hot-toast'
import {
    optionsCategory,
    descriptionMapper,
    optionsCSP,
    optionsDeviceType,
    optionsRegion,
    optionsRegionGCP,
    optionsRegionAWS,
    optionsRegionAZURE,
} from './Mapping/MappingList'
import {
    styleConfirmationModal,
    subscriptionModalBoxStyle,
} from './Mapping/subscriptionModalBoxStyle'
import CloseButton from './Mapping/Buttons/Close Button/closeButton'
import SaveButton from './Mapping/Buttons/Save Button/saveButton'
import { modalLoaderStyle } from './Mapping/ModalLoader/ModalLoaderStyles'
import { handlePreferenceSave } from './utils/handlePreferenceSave'
import { api } from '../../axiosWrapper.js'

export default function SubscriptionModal({ open, handleClose }) {
    const dispatch = useDispatch()
    const { userInfo, rawPreferences } =
        useSelector((state) => state.userInfo) || {}
    const { preference, currentPreference } = userInfo || {}
    const isAuthenticated = useIsAuthenticated()
    const { accounts } = useMsal()
    const [loading, setLoading] = useState(false) // Initialize the loading state
    const [categoryDropdown, setCategoryDropdown] = useState('')
    const [regionDropdown, setRegionDropdown] = useState('')
    const [deviceType, setDeviceType] = useState('')
    const [cspDropDown, setCspDropDown] = useState('')
    const [data, setData] = useState([])
    const [disableSelectServices, setDisableSelectServices] = useState(true) // Add loading state
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
    const [yesClicked, setYesClicked] = useState(false)
    const [selectedOptions, setSelectedOptions] = useState([])
    const [resetOptions, setResetOptions] = useState(false)
    //Fetch dynamic data based on dropdown selection from the backend API

    const fetchData = useCallback(async () => {
        setLoading(true) // Set loading to true when starting to fetch data

        try {
            if (
                categoryDropdown === 'cloudNetwork' &&
                regionDropdown !== null &&
                cspDropDown !== null &&
                deviceType !== null
            ) {
                const res = await api.get(
                    `${url}/dynamicDropDownList/${categoryDropdown}/${regionDropdown}/${deviceType}/${cspDropDown}`
                )

                const { data } = res
                const { documents } = data[0]
                const newData = documents.filter(
                    (doc) => !preference.includes(doc?.product_name)
                )
                setData(newData)
            } else if (categoryDropdown === 'CMP') {
                const res = await api.get(
                    `${url}/dynamicDropDownList/${categoryDropdown}`
                )

                if (res && res.data && res.data.length > 0) {
                    const newData = res?.data[0]?.documents.filter(
                        (doc) => !preference.includes(doc?.product_name)
                    )

                    setData(newData)
                }
            } else if (categoryDropdown === 'GCP' && regionDropdown !== null) {
                try {
                    // Fetch data specifically for GCP category
                    const res = await api.get(
                        `${url}/api/others/findServicesByCSPRegion/${regionDropdown}/${categoryDropdown}`
                    )
                    const newData = res.data
                        .filter(
                            (e) => !preference.includes(e.serviceNameParameter)
                        )
                        .map((service) => ({
                            product_name: service.serviceNameUI,
                            product_identifier: service.serviceNameParameter,
                        }))
                    setData(newData)
                } catch (error) {
                    console.error('Error fetching GCP data:', error)
                    // Handle error, show error message to user, etc.
                }
            } else if (categoryDropdown === 'AWS' && regionDropdown !== null) {
                try {
                    // Fetch data specifically for GCP category
                    const res = await api.get(
                        `${url}/api/others/findServicesByCSPRegion/${regionDropdown}/${categoryDropdown}`
                    )
                    const newData = res.data
                        .filter(
                            (e) => !preference.includes(e.serviceNameParameter)
                        )
                        .map((service) => ({
                            product_name: service.serviceNameUI,
                            product_identifier: service.serviceNameParameter,
                        }))
                    setData(newData)
                } catch (error) {
                    console.error('Error fetching GCP data:', error)
                    // Handle error, show error message to user, etc.
                }
            } else if (
                categoryDropdown === 'AZURE' &&
                regionDropdown !== null
            ) {
                try {
                    // Fetch data specifically for GCP category
                    const res = await api.get(
                        `${url}/api/others/findServicesByCSPRegion/${regionDropdown}/${categoryDropdown}`
                    )
                    const newData = res.data
                        .filter(
                            (e) => !preference.includes(e.serviceNameParameter)
                        )
                        .map((service) => ({
                            product_name: service.serviceNameUI,
                            product_identifier: service.serviceNameParameter,
                        }))
                    setData(newData)
                } catch (error) {
                    console.error('Error fetching GCP data:', error)
                    // Handle error, show error message to user, etc.
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error)
        } finally {
            setLoading(false) // Set loading to false when the fetch operation is complete
        }
    }, [categoryDropdown, preference, deviceType, cspDropDown, regionDropdown])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    useEffect(() => {
        setCategoryDropdown(null)
        setDeviceType(null)
        setCspDropDown(null)
        setRegionDropdown(null)
        setData([])
    }, [open])
    const cleanPreference = (yesClicked) => {
        if (yesClicked) {
            const localUserData = {
                ...userInfo,
                preference: currentPreference,
            }

            // dispatch(setUserInfo(localUserData));
        }
    }
    const closeModalAndClearStates = async () => {
        // Clear the selections and close the modal
        setYesClicked(false)
        cleanPreference(true)
        setConfirmDialogOpen(false)
        setCategoryDropdown(null)
        setDeviceType(null)
        setCspDropDown(null)
        setRegionDropdown(null)
        setData([])
        setHasUnsavedChanges(false)
        handleClose()
    }

    const handleYesClickedAndDiscardChanges = async () => {
        setYesClicked(true)
        cleanPreference(true)
        setConfirmDialogOpen(false)
        setCategoryDropdown(null)
        setDeviceType(null)
        setCspDropDown(null)
        setRegionDropdown(null)
        setData([])
        setHasUnsavedChanges(false)
        handleClose()
    }

    const handleSave = async () => {
        if (rawPreferences.length === 0 && selectedOptions.length === 0) {
            return toast.error('No product/service selected to subscribe')
        }

        // if (
        //     rawPreferences.length !== 0 &&
        //     selectedOptions.length === 0 &&
        //     preference.length === 0
        // ) {
        //     alert(
        //         'No product/service selected to subscribe,do you want to unsubscribe all selection?'
        //     )
        // }

        // Note => Handle UI to ask consent when user is unsubscribing all
        let preferenceData = preference
        if (selectedOptions.length > 0) {
            preferenceData = [
                ...preferenceData,
                ...selectedOptions.map(
                    (e) => e.product_identifier || e.product_name
                ),
            ]
        }
        const localUserData = {
            ...userInfo,
            currentPreference: preferenceData,
        }

        dispatch(setRawPreferences(preferenceData))
        dispatch(setUserInfo(localUserData))

        await handlePreferenceSave(
            closeModalAndClearStates,
            updatePreferences,
            accounts,
            preferenceData,
            toast
        )
    }

    const handleUpdateCategory = useCallback((category) => {
        setCategoryDropdown(category)
        setHasUnsavedChanges(true)
    }, [])
    const handleUpdateRegion = useCallback((region) => {
        setRegionDropdown(region)
        setHasUnsavedChanges(true)
    }, [])

    const handleUpdateDeviceType = useCallback((device) => {
        setDeviceType(device)
        setHasUnsavedChanges(true)
    }, [])
    const handleUpdateCsp = useCallback((csp) => {
        setCspDropDown(csp)
        setHasUnsavedChanges(true)
    }, [])

    useEffect(() => {
        if (categoryDropdown === 'CMP') {
            setDisableSelectServices(false)
        } else if (categoryDropdown === 'cloudNetwork') {
            if (
                cspDropDown &&
                cspDropDown.length > 0 &&
                regionDropdown &&
                regionDropdown.length > 0 &&
                deviceType &&
                deviceType.length > 0
            ) {
                setDisableSelectServices(false)
            } else {
                setDisableSelectServices(true)
            }
        } else if (categoryDropdown === 'AZURE') {
            if (regionDropdown && regionDropdown.length > 0) {
                setDisableSelectServices(false)
            } else {
                setDisableSelectServices(true)
            }
        } else if (categoryDropdown === 'GCP') {
            if (regionDropdown && regionDropdown.length > 0) {
                setDisableSelectServices(false)
            } else {
                setDisableSelectServices(true)
            }
        } else if (categoryDropdown === 'AWS') {
            if (regionDropdown && regionDropdown.length > 0) {
                setDisableSelectServices(false)
            } else {
                setDisableSelectServices(true)
            }
        }
    }, [categoryDropdown, regionDropdown, deviceType, cspDropDown])

    const handleUpdateSelectedServices = (selectedServices) => {
        setHasUnsavedChanges(true)

        const rawResult = selectedServices.map((e) => e.product_name)
        const newResult = [
            ...new Set([...preference, ...rawResult, ...selectedOptions]),
        ]
        const localUserData = {
            ...userInfo,
            preference: newResult,
        }
        dispatch(setUserInfo(localUserData))
    }

    const openConfirmDialog = () => {
        setConfirmDialogOpen(true)
    }

    const handleModalClose = useCallback(() => {
        if (hasUnsavedChanges) {
            openConfirmDialog()
        } else {
            handleClose()
        }
    }, [hasUnsavedChanges, openConfirmDialog])

    const dropdownInfo = {
        region: {
            placeholder: 'Select Region',
            values: optionsRegion,
            disabled: false,
            updateMethod: handleUpdateRegion,
            description:
                'This option allows you to filter products/services in different parts of the world',
        },
        deviceType: {
            placeholder: 'Select Device Type',
            values: optionsDeviceType,
            disabled: !regionDropdown || regionDropdown.length === 0,
            updateMethod: handleUpdateDeviceType,
            description: 'Choose to subscribe to Palo Alto/F5 device alerts',
        },

        csp: {
            placeholder: 'Select CSP',
            values: optionsCSP,
            disabled: !(deviceType && deviceType.length > 0),
            updateMethod: handleUpdateCsp,
            description: 'Select your preferred Cloud Service Provider',
        },
    }
    const dropdownInfoAZURE = {
        region: {
            placeholder: 'Select Region',
            values: optionsRegionAZURE.filter((e) => e.active),
            disabled: false,
            updateMethod: handleUpdateRegion,
            description:
                'This option allows you to filter products/services in different parts of the world',
        },
    }
    const dropdownInfoGCP = {
        region: {
            placeholder: 'Select Region',
            values: optionsRegionGCP,
            disabled: false,
            updateMethod: handleUpdateRegion,
            description:
                'This option allows you to filter products/services in different parts of the world',
        },
    }
    const dropdownInfoAWS = {
        region: {
            placeholder: 'Select Region',
            values: optionsRegionAWS,
            disabled: false,
            updateMethod: handleUpdateRegion,
            description:
                'This option allows you to filter products/services in different parts of the world',
        },
    }
    return (
        <div>
            <Toaster />
            <Modal
                open={open}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Fade in={open}>
                    <Box
                        sx={subscriptionModalBoxStyle}
                        className="subscription-modal"
                    >
                        <div className="subscription-modal-main-container">
                            <WelcomeContainer onClose={handleModalClose} />
                            {loading && (
                                <div style={modalLoaderStyle}>
                                    <CircularProgress />
                                </div>
                            )}
                            <hr className="verticalShadowLine" />
                            <div
                                style={{
                                    height: '55vh',
                                    overflowY: 'auto',
                                }}
                            >
                                {/* Current Subscriptions */}
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '30%',
                                        }}
                                    >
                                        <Typography className="leftLabel">
                                            Current Subscriptions -
                                        </Typography>
                                        <Tooltip
                                            arrow
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            fontSize="medium"
                                            placement="right"
                                            title="Size of the subscription is limited to 8 letters. To view the full name, please hover over the subscription name."
                                        >
                                            <span>
                                                <InfoOutlinedIcon className="info-icon-alerts-modal" />
                                            </span>
                                        </Tooltip>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '70%',
                                        }}
                                    >
                                        <CurrentSubscriptions
                                            currentPreference={
                                                currentPreference
                                            }
                                            setHasUnsavedChanges={
                                                setHasUnsavedChanges
                                            }
                                            yesClicked={yesClicked}
                                        />
                                    </div>
                                </div>

                                <div>
                                    {/* Edit / Add new Subscriptions Label*/}
                                    <div>
                                        <Typography className="modal-modal-description">
                                            Edit / Add new Subscriptions
                                        </Typography>
                                    </div>

                                    {/* Category DropDown */}
                                    <div className="reusable-dropdown-container">
                                        <div className="reusable-dropdown-left-label-container">
                                            <Typography className="leftLabel">
                                                Select Category -
                                            </Typography>

                                            <Typography className="description-label">
                                                {descriptionMapper[0]}
                                            </Typography>
                                        </div>

                                        <div className="reusable-dropdown-right-dropdown-container">
                                            <ReusableDropdown
                                                placeholder="Select Category"
                                                values={optionsCategory}
                                                disabled={false}
                                                onChangeUpdate={
                                                    handleUpdateCategory
                                                }
                                            />
                                        </div>
                                    </div>

                                    {categoryDropdown === 'cloudNetwork' && (
                                        <div>
                                            {Object.keys(dropdownInfo).map(
                                                (key) => (
                                                    <div
                                                        className="reusable-dropdown-container"
                                                        key={key}
                                                    >
                                                        <div className="reusable-dropdown-left-label-container">
                                                            <Typography className="leftLabel">
                                                                {
                                                                    dropdownInfo[
                                                                        key
                                                                    ]
                                                                        .placeholder
                                                                }{' '}
                                                                -
                                                            </Typography>

                                                            <Typography className="description-label">
                                                                {
                                                                    dropdownInfo[
                                                                        key
                                                                    ]
                                                                        .description
                                                                }
                                                            </Typography>
                                                        </div>
                                                        <div className="reusable-dropdown-right-dropdown-container">
                                                            <ReusableDropdown
                                                                placeholder={
                                                                    dropdownInfo[
                                                                        key
                                                                    ]
                                                                        .placeholder
                                                                }
                                                                values={
                                                                    dropdownInfo[
                                                                        key
                                                                    ].values
                                                                }
                                                                disabled={
                                                                    dropdownInfo[
                                                                        key
                                                                    ].disabled
                                                                }
                                                                onChangeUpdate={
                                                                    dropdownInfo[
                                                                        key
                                                                    ]
                                                                        .updateMethod
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    )}

                                    {categoryDropdown === 'GCP' && (
                                        <div>
                                            {Object.keys(dropdownInfoGCP).map(
                                                (key) => (
                                                    <div
                                                        className="reusable-dropdown-container"
                                                        key={key}
                                                    >
                                                        <div className="reusable-dropdown-left-label-container">
                                                            <Typography className="leftLabel">
                                                                {
                                                                    dropdownInfoGCP[
                                                                        key
                                                                    ]
                                                                        .placeholder
                                                                }{' '}
                                                                -
                                                            </Typography>
                                                            <Typography className="description-label">
                                                                {
                                                                    dropdownInfoGCP[
                                                                        key
                                                                    ]
                                                                        .description
                                                                }
                                                            </Typography>
                                                        </div>
                                                        <div className="reusable-dropdown-right-dropdown-container">
                                                            <ReusableDropdown
                                                                placeholder={
                                                                    dropdownInfoGCP[
                                                                        key
                                                                    ]
                                                                        .placeholder
                                                                }
                                                                values={
                                                                    dropdownInfoGCP[
                                                                        key
                                                                    ].values
                                                                }
                                                                disabled={
                                                                    dropdownInfoGCP[
                                                                        key
                                                                    ].disabled
                                                                }
                                                                onChangeUpdate={
                                                                    dropdownInfoGCP[
                                                                        key
                                                                    ]
                                                                        .updateMethod
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    )}
                                    {categoryDropdown === 'AZURE' && (
                                        <div>
                                            {Object.keys(dropdownInfoAZURE).map(
                                                (key) => (
                                                    <div
                                                        className="reusable-dropdown-container"
                                                        key={key}
                                                    >
                                                        <div className="reusable-dropdown-left-label-container">
                                                            <Typography className="leftLabel">
                                                                {
                                                                    dropdownInfoAZURE[
                                                                        key
                                                                    ]
                                                                        .placeholder
                                                                }{' '}
                                                                -
                                                            </Typography>
                                                            <Typography className="description-label">
                                                                {
                                                                    dropdownInfoAZURE[
                                                                        key
                                                                    ]
                                                                        .description
                                                                }
                                                            </Typography>
                                                        </div>
                                                        <div className="reusable-dropdown-right-dropdown-container">
                                                            <ReusableDropdown
                                                                placeholder={
                                                                    dropdownInfoAZURE[
                                                                        key
                                                                    ]
                                                                        .placeholder
                                                                }
                                                                values={
                                                                    dropdownInfoAZURE[
                                                                        key
                                                                    ].values
                                                                }
                                                                disabled={
                                                                    dropdownInfoAZURE[
                                                                        key
                                                                    ].disabled
                                                                }
                                                                onChangeUpdate={
                                                                    dropdownInfoAZURE[
                                                                        key
                                                                    ]
                                                                        .updateMethod
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    )}

                                    {categoryDropdown === 'AWS' && (
                                        <div>
                                            {Object.keys(dropdownInfoAWS).map(
                                                (key) => (
                                                    <div
                                                        className="reusable-dropdown-container"
                                                        key={key}
                                                    >
                                                        <div className="reusable-dropdown-left-label-container">
                                                            <Typography className="leftLabel">
                                                                {
                                                                    dropdownInfoAWS[
                                                                        key
                                                                    ]
                                                                        .placeholder
                                                                }{' '}
                                                                -
                                                            </Typography>
                                                            <Typography className="description-label">
                                                                {
                                                                    dropdownInfoAWS[
                                                                        key
                                                                    ]
                                                                        .description
                                                                }
                                                            </Typography>
                                                        </div>
                                                        <div className="reusable-dropdown-right-dropdown-container">
                                                            <ReusableDropdown
                                                                placeholder={
                                                                    dropdownInfoAWS[
                                                                        key
                                                                    ]
                                                                        .placeholder
                                                                }
                                                                values={
                                                                    dropdownInfoAWS[
                                                                        key
                                                                    ].values
                                                                }
                                                                disabled={
                                                                    dropdownInfoAWS[
                                                                        key
                                                                    ].disabled
                                                                }
                                                                onChangeUpdate={
                                                                    dropdownInfoAWS[
                                                                        key
                                                                    ]
                                                                        .updateMethod
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    )}
                                    {selectedOptions.length > 0 && (
                                        <div
                                            style={{
                                                display: 'flex',
                                                marginLeft: '45em',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            <button
                                                style={{
                                                    fontSize: '14px',
                                                    color: 'red',
                                                    backgroundColor: 'white',
                                                    border: '1px solid red',
                                                    borderRadius: '16px',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    setSelectedOptions([])
                                                    setResetOptions(
                                                        (prev) => !prev
                                                    )
                                                }}
                                            >
                                                Clear All
                                            </button>
                                        </div>
                                    )}
                                    {categoryDropdown &&
                                    categoryDropdown.length > 0 ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: '30%',
                                                }}
                                            >
                                                <Typography className="leftLabel">
                                                    Select Product/Service -
                                                </Typography>
                                                <Tooltip
                                                    arrow
                                                    TransitionComponent={Fade}
                                                    TransitionProps={{
                                                        timeout: 600,
                                                    }}
                                                    fontSize="medium"
                                                    placement="right"
                                                    title="Size of the subscription is limited to 8 letters. To view the full name, please hover over the subscription name."
                                                >
                                                    <span>
                                                        <InfoOutlinedIcon className="info-icon-alerts-modal" />
                                                    </span>
                                                </Tooltip>
                                            </div>

                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: '70%',
                                                }}
                                            >
                                                <NewSubscriptionsList
                                                    placeholder="Choose products/services to subscribe to"
                                                    subscriptions={data}
                                                    onUpdateSubscriptions={
                                                        handleUpdateSelectedServices
                                                    }
                                                    disabled={
                                                        disableSelectServices
                                                    }
                                                    selectedOptions={
                                                        selectedOptions
                                                    }
                                                    setSelectedOptions={
                                                        setSelectedOptions
                                                    }
                                                    resetOptions={resetOptions}
                                                />
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <hr className="verticalShadowLine" />
                            <div className="cancel-update-container">
                                <CloseButton handleClose={handleModalClose} />
                                <SaveButton handleSave={handleSave} />
                            </div>{' '}
                        </div>
                    </Box>
                </Fade>
            </Modal>

            <Modal
                open={confirmDialogOpen}
                onClose={() => setConfirmDialogOpen(false)}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
            >
                <Fade in={confirmDialogOpen}>
                    <Box
                        style={styleConfirmationModal}
                        className="confirmation-dialog"
                    >
                        <Typography
                            id="confirm-dialog-title"
                            variant="h6"
                            sx={{ marginBottom: 2 }}
                        >
                            Are you sure?
                        </Typography>
                        <Typography
                            id="confirm-dialog-description"
                            sx={{ marginBottom: 4 }}
                        >
                            You have unsaved changes. Do you want to discard
                            them?
                        </Typography>
                        <div className="confirmation-dialog-buttons">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleYesClickedAndDiscardChanges}
                                sx={{
                                    marginRight: 2,
                                    color: 'red',
                                    backgroundColor: 'white',
                                    '&:hover': {
                                        color: 'white',
                                        backgroundColor: 'red',
                                    },
                                }}
                            >
                                Yes
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                sx={{
                                    color: 'green',
                                    backgroundColor: 'white',
                                    '&:hover': {
                                        color: 'white',
                                        backgroundColor: 'green',
                                    },
                                }}
                                onClick={() => {
                                    setConfirmDialogOpen(false)
                                    setYesClicked(false)
                                }}
                            >
                                No
                            </Button>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    )
}
